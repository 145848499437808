import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { FeatureFlags } from '../../../components/FeatureFlags';

const adminUpdateOrganizationFeatureFlagsMutation = gql`
  mutation AdminUpdateOrganizationFeatureFlags(
    $input: AdminUpdateOrganizationInput!
  ) {
    adminUpdateOrganization(input: $input) {
      organization {
        id
        featureFlags
      }
    }
  }
`;

interface FeatureFlagsPanelProps {
  organization: {
    id: string;
    featureFlags: Record<string, boolean>;
  };
}

export const FeatureFlagsPanel: React.FC<FeatureFlagsPanelProps> = ({
  organization,
}) => {
  const [updateOrganization] = useMutation(
    adminUpdateOrganizationFeatureFlagsMutation
  );

  return (
    <FeatureFlags
      initialValues={organization.featureFlags}
      update={(flag, value) => {
        updateOrganization({
          variables: {
            input: {
              id: organization.id,
              featureFlags: {
                [flag]: value,
              },
            },
          },
        });
      }}
    />
  );
};
