import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Paper, Tab, Tabs, useTheme } from '@mui/material';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUIItem, setUIItem } from '@pv/common/utils';
import { PageLoading } from '@pv/common/components';
import { organizationQuery } from './graphql';
import { UsersPanel } from './components/UsersPanel';
import { VenuesPanel } from './components/VenuesPanel';
import { InfoPanel } from './components/InfoPanel';
import { FeatureFlagsPanel } from './components/FeatureFlagsPanel';
import { Header } from './components/Header';

const TabPanel = ({ children, value, index }) => {
  if (value !== index) {
    return null;
  }
  return <div>{children}</div>;
};

const tabKey = 'org-tab';
const findInitalTab = () => getUIItem(tabKey) || 'info';

export const Organization = () => {
  const theme = useTheme();
  const [tab, setTab] = useState(findInitalTab());
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const organizationId = params.id;
  const variables = { id: organizationId };
  const { data, refetch } = useQuery(organizationQuery, { variables });
  const organization = data?.organization;
  const availablePlanSets = data?.adminPlanSets;

  const onTabChange = (e, t) => {
    navigate(`/admin/organizations/${organization.id}/${t}`);
    setTab(t);
    setUIItem(tabKey, t);
  };

  useEffect(() => {
    const barePathMatch = location.pathname.match(
      /\/admin\/organizations\/(\d+)$/
    );
    if (barePathMatch) {
      navigate(`/admin/organizations/${barePathMatch[1]}/info`, {
        replace: true,
      });
      setTab('info');
    } else if (location.pathname.match(/\/admin\/organizations\/\d+\/info$/)) {
      setTab('info');
    } else if (location.pathname.match(/\/admin\/organizations\/\d+\/venues/)) {
      setTab('venues');
    } else if (location.pathname.match(/\/admin\/organizations\/\d+\/users/)) {
      setTab('users');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (!organization) {
    return <PageLoading />;
  }

  return (
    <div>
      <Header organization={organization} />
      <Paper>
        <Box
          style={{
            flexGrow: 1,
            display: 'flex',
          }}
        >
          <div style={{ flexShrink: 1 }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tab || 'info'}
              onChange={onTabChange}
              indicatorColor={'secondary'}
              sx={{
                borderRight: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Tab value="info" label="Info" />
              <Tab value="feature-flags" label="Feature Flags" />
              <Tab value="venues" label="Venues" />
              <Tab value="users" label="Users" />
            </Tabs>
          </div>
          <div style={{ width: '100%', flexGrow: 1, minHeight: 500 }}>
            <TabPanel value={tab} index="info">
              <InfoPanel
                organization={organization}
                availablePlanSets={availablePlanSets}
              />
            </TabPanel>
            <TabPanel value={tab} index="feature-flags">
              <FeatureFlagsPanel organization={organization} />
            </TabPanel>
            <TabPanel value={tab} index="venues">
              <VenuesPanel organization={organization} afterConfirm={refetch} />
            </TabPanel>
            <TabPanel value={tab} index="users">
              <UsersPanel organization={organization} />
            </TabPanel>
          </div>
        </Box>
      </Paper>
    </div>
  );
};

export default Organization;
