import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { AdminVenue } from '@pv/common/graphql/types';
import { FeatureFlags } from '../../../components/FeatureFlags';

const adminUpdateVenueFeatureFlagsMutation = gql`
  mutation AdminUpdateVenueFeatureFlags($input: AdminUpdateVenueInput!) {
    adminUpdateVenue(input: $input) {
      adminVenue {
        id
        featureFlags
      }
    }
  }
`;

interface FeatureFlagsPanelProps {
  venue: AdminVenue;
}

export const FeatureFlagsPanel: React.FC<FeatureFlagsPanelProps> = ({
  venue,
}) => {
  const [updateVenue] = useMutation(adminUpdateVenueFeatureFlagsMutation);

  return (
    <FeatureFlags
      initialValues={venue.featureFlags}
      update={(flag, value) => {
        updateVenue({
          variables: {
            input: {
              id: venue.id,
              featureFlags: {
                [flag]: value,
              },
            },
          },
        });
      }}
    />
  );
};
