import { gql } from '@apollo/client';

export const organizationFields = gql`
  fragment OrganizationFields on Organization {
    name
    billingEmail
    stripeLast4
    discount
    stripeCustomerId
    isCcPaymentEnabled
    createdAt
    discount
    planSet {
      id
    }
    venues {
      id
      name
      planName
      cardApplicationFeeRate
      achApplicationFeeRate
      monthlyRate
    }
    users {
      id
      role
      email
      firstName
      lastName
      magicLoginLink
    }
    featureFlags
  }
`;

export const updateOrganizationMutation = gql`
  mutation UpdateAdminOrganization($input: AdminUpdateOrganizationInput!) {
    adminUpdateOrganization(input: $input) {
      organization {
        id
        ...OrganizationFields
      }
      errors {
        message
      }
    }
  }
  ${organizationFields}
`;

export const organizationQuery = gql`
  query AdminOrganization($id: ID!) {
    organization(id: $id) {
      id
      ...OrganizationFields
    }
    adminPlanSets {
      id
      name
      planDefinitions {
        id
        planName
        monthlyRate
        billingInterval
        stripeProductId
        stripePriceId
        cardApplicationFeeRate
        achApplicationFeeRate
        tier
        active
      }
    }
  }
  ${organizationFields}
`;
