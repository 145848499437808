import React from 'react';
import { Box, FormControlLabel, Checkbox } from '@mui/material';

interface FeatureFlagsPanelProps {
  update: (flag: string, value: boolean) => void;
  initialValues: Record<string, boolean>;
}

export const FeatureFlags: React.FC<FeatureFlagsPanelProps> = ({
  update,
  initialValues,
}) => {
  const [featureFlags, setFeatureFlags] = React.useState(initialValues);

  const formatFlagName = (flag: string) => {
    const formattedFlagName = flag.replace(/([A-Z])/g, ' $1');
    return (
      formattedFlagName.charAt(0).toUpperCase() + formattedFlagName.slice(1)
    );
  };

  return (
    <Box>
      {Object.entries(initialValues).map(([flag, value]) => (
        <Box key={flag}>
          <FormControlLabel
            label={formatFlagName(flag)}
            control={
              <Checkbox
                checked={!!value}
                color="primary"
                onChange={() => {
                  setFeatureFlags({
                    ...featureFlags,
                    [flag]: !value,
                  });
                  update(flag, !value);
                }}
              />
            }
          />
        </Box>
      ))}
    </Box>
  );
};
